import React, { Component } from 'react'
import { Button, Icon, AnchorButton } from '@blueprintjs/core'
import './style.css';
import { Link } from 'react-router-dom';

const menuItems = [{
    id:0,
    name:'Design',
    icon:'page-layout',
    url:'/app/'
},  {
    id:1,
    name:'Unsubscribers',
    icon:'graph-remove',
    url:'/app/unsubscribers'
}, 
{
    id:4,
    name:'Mail Activity',
    icon:'comparison',
    url:'/app/activity'
},
{
    id:3,
    name:'Settings',
    icon:'cog',
    url:'/app/settings/email'
}]

// {
//     id:2,
//     name:'Send Analytics',
//     icon:'grouped-bar-chart',
//     url:'/app/activity'
// },
export default class DashSidebar extends Component {

    // componentDidMount() {

    //     var HW_config = {
    //         selector: ".headwayapp-icon", // CSS selector where to inject the badge
    //         account:  "7egOpy",
    //         trigger:'.headwayapp-icon'
    //       };


    //     if(window.Headway)
    //         window.Headway.init(HW_config);
    // }
    render() {
        return (
            <div className="dash-side">
                <div className='flex flex-column justify-between' style={{ height:'100%' }}>
                    <ul className='dash-menu-ul list-none'>
                        {
                        menuItems.map(item => (
                                <li key={item.id} className="dash-menu-li">
                                    <Link to={item.url} className={`bp3-button bp3-minimal bp3-fill dash-menu-li-button ${this.props.active  == item.id ? 'bp3-intent-primary dash-menu-li-button--active' : ''}`}>
                                        <Icon iconSize={20} icon={item.icon} />
                                        <span className="txt">{item.name}</span>
                                    </Link>
                                </li>
                        )) 
                        }
                    
                    
                    </ul>

                    <ul className='dash-menu-ul list-none'>




                        {/* <li  className="dash-menu-li">
                            <a className={`bp3-button relative bp3-minimal bp3-fill  headwayapp-icon`}>
                             
                            </a>
                        </li> */}

                        <li  className="dash-menu-li">
                            <a target="_blank" href="https://www.emailkick.com/docs/" className={`bp3-button relative bp3-minimal bp3-fill dash-menu-li-button `}>
                                <Icon iconSize={20} icon="lifesaver" />
                                <span className="txt">Documentation</span>
                            </a>
                        </li>


                        <li  className="dash-menu-li">
                                    <a href={'/logout'} className={`bp3-button bp3-minimal bp3-fill dash-menu-li-button`}>
                                        <Icon iconSize={20} icon="log-out" />
                                        <span className="txt">Logout</span>
                                    </a>
                        </li>

                        
                    
                    
                    </ul>


                </div>
                
            </div>
        )   
    }
}
