import React, { Component } from 'react'
import { FormGroup, InputGroup, Button, Intent, Spinner, Callout } from '@blueprintjs/core';
// import './style.css';
import { DATA_URL, setUserInfo, strengthIndicator, strengthColor } from '../../constants';




const SuccessMessage = () => {
    return (
        <div className="app-container content-container">
            <div className="container mx-auto pt4  max-width-2">
                <div className='login-container mt-4' style={{ gridTemplateColumns: '1fr',  }}>
                    <div className="login-form-wrapper">
                        <div className="head">
                     
                                <div className="logo-group">
                                    <div className="logo-icon"><img src={require('../../assets/images/mailsendLogo.svg')} /></div>
                                    <div className="logo-text">emailkick</div>
                                </div>

                                <div style={{ margin: '3rem 0 0' }}>
                                    <Callout intent={Intent.WARNING} icon="tick-circle" title="Invalid Token">
                                        <p className="py2">Seems like the token is either expired or does not exist. Please try login again.</p>
                                        <a className="bp3-button bp3-large" href="/login">Login to Dashboard</a>
                                    </Callout>
                                </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
    
}
export default class ResetPasswordInvalid extends Component {


    state = {
        email: '',
        loading:false,
        success: false,
        error: null,
        password: '',
        confirmPassword: '',
    }


    submitHandler = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        })
        fetch(DATA_URL+'auth/forgetpasswordconfirm', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
              },
                  body: JSON.stringify({
                      token: this.props.match.params.id,
                      ...this.state
                  }
              ) 
        }).then(r => r.json()).then(r => {
            if(r.success) {
                this.setState({
                    success: true
                })
            }

            else {
                this.setState({
                    loading: false,
                    error: r.error
                })
            }
        }).catch(err => {
            this.setState({
                loading: false,
                error: 'Server Issue'
            })
        })
    }

    changePassword = (e) => {
        const strngt =  strengthIndicator(e.target.value);
        console.log(strngt)
        this.setState({
            password: e.target.value,
            passwordStrength: strngt,
            passwordStrengthColor : strengthColor(strngt)
        });


    }

    changeConfirmPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value,
            confirmPasswordStatus:  e.target.value == this.state.password & e.target.value.length > 3
        })
    }


    changeEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    changeName = (e) => {
        this.setState({
            fullname: e.target.value
        })
    }

    changeBetaCode = (e) => {
        this.setState({
            betaCode: e.target.value
        })
    }
    render() {


            return <SuccessMessage />


    }
}
