import React, { Component } from 'react'
import { FormGroup, InputGroup, Button, Intent, Spinner, Callout } from '@blueprintjs/core';
// import './style.css';
import { INTENT_PRIMARY } from '@blueprintjs/core/lib/esm/common/classes';
import { DATA_URL, setUserInfo, strengthIndicator, strengthColor, LOGOIMGURL } from '../../constants';




const SuccessMessage = () => {
    return (
        <div className="app-container content-container">
            <div className="container mx-auto pt4  max-width-2">
                <div className='login-container mt-4' style={{ gridTemplateColumns: '1fr',  }}>
                    <div className="login-form-wrapper">
                        <div className="head">
                     
                                <div className="logo-group">
                                    <div className="logo-icon"><img src={require('../../assets/images/mailsendLogo.svg')} /></div>
                                    <div className="logo-text">emailkick</div>
                                </div>

                                <div style={{ margin: '3rem 0 0' }}>
                                    <Callout intent={Intent.SUCCESS} icon="tick-circle" title="Password Reset Link Sent">
                                   
                                        <p className="py2">We've sent an email to your email address with password reset instructions.</p>
                                    </Callout>
                                </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
    
}
export default class ForgetPassword extends Component {


    state = {
        email: '',
        loading:false,
        success: false,
        error: null
    }


    submitHandler = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        })
        fetch(DATA_URL+'auth/forgetpassword', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
              },
                  body: JSON.stringify({
                      ...this.state
                  }
              ) 
        }).then(r => r.json()).then(r => {
            if(r.success) {
                this.setState({
                    success: true
                })
            }

            else {
                this.setState({
                    loading: false,
                    error: r.error
                })
            }
        }).catch(err => {
            this.setState({
                loading: false,
                error: 'Server Issue'
            })
        })
    }

    changePassword = (e) => {
        const strngt =  strengthIndicator(e.target.value);
        this.setState({
            password: e.target.value,
            passwordStrength: strngt,
            passwordStrengthColor : strengthColor(strngt)
        });


    }

    changeConfirmPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value,
            confirmPasswordStatus:  e.target.value == this.state.password & e.target.value.length > 3
        })
    }


    changeEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    changeName = (e) => {
        this.setState({
            fullname: e.target.value
        })
    }

    changeBetaCode = (e) => {
        this.setState({
            betaCode: e.target.value
        })
    }
    render() {


        if(this.state.success)
            return <SuccessMessage />

        return (
            <div className="app-container content-container">
                <div className="container mx-auto pt4  max-width-2">
                    <div className='login-container mt-4' style={{ gridTemplateColumns: '1fr',  }}>
                        
                        <div className="login-form-wrapper">
                            <div className="mb3 mt1 ">
                                <div className="logo-group items-center mb3 ">
                                    <div className="logo-icon  items-center"><img src={LOGOIMGURL} /></div>
                                    <div className="logo-text">emailkick</div>
                                </div>


                                <div style={{ margin: '1rem 0 0' }}>
                                    <h3 className="mb1 strong mt1">Reset Your Password</h3>
                                    <p>We’ll email you instructions to reset your password.</p>
                                </div>
                            </div>

                            { this.state.error ? <Callout className="mb1" intent={Intent.WARNING}>{this.state.error}</Callout> : null }

                            <form onSubmit={this.submitHandler}>
                                <FormGroup
                                    label="Email Address"
                                    labelFor="text-input"
                                  
                                >
                                    <InputGroup required type="email" onChange={this.changeEmail} className="mt1" large id="text-input" placeholder="someone@somewhere.com" />
                                </FormGroup>
                               
                                <div className="mt3">
                                    <Button type="submit" large className="pri-button" intent={Intent.PRIMARY}>{ this.state.loading ? <Spinner size='20' /> : 'Reset Password' }</Button>
                                </div>

                                <div className="mt4">
                                    <h5 className="mb1">Back to Login?</h5>
                                    <a href="/login">Login in now</a>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}
