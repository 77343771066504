import React, { Component } from 'react'
import { FormGroup, InputGroup, Button, Intent, Spinner, Callout } from '@blueprintjs/core';
import './style.css';
import { INTENT_PRIMARY } from '@blueprintjs/core/lib/esm/common/classes';
import { DATA_URL, LOGOIMGURL, setUserInfo } from '../../constants';
export default class Login extends Component {


    state = {
        email: '',
        password: '',
        error: null,
        loading: false
    }


    submitHandler = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        })
        fetch(DATA_URL+'auth/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
              },
                  body: JSON.stringify({
                      ...this.state
                  }
              ) 
        }).then(r => r.json()).then(r => {
            if(r.user) {
                setUserInfo(r.user);
                window.location.href = '/app'
            }

            else {
                this.setState({
                    loading: false,
                    error: 'Email or Password is wrong'
                })
            }
               
            

        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    }

    changePassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }


    changeEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }
    render() {
        return (
            <div className="app-container content-container flex items-center justify-center">
                <div className="container mx-auto  max-width-4">
                    <div className='login-container rounded-lg shadow-sm border-gray-200'>
                        
                        <div className="flex justify-between flex-col">
                            <div className="head py-10 px-6 mb-3">
                                <div className="logo-group flex items-center">
                                    <div className="logo-icon"><img src={LOGOIMGURL} /></div>
                                    <div className="logo-text">emailkick</div>
                                </div>
                            </div>
                            
                            <div className="px-6">
                                {this.state.error ? <Callout className="mb2" intent={Intent.WARNING}>{this.state.error} </Callout> : null }
                            </div>

                            <form className="px-6 py-6" onSubmit={this.submitHandler}>
                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Email Address"
                                    labelFor="text-input"
                                    //labelInfo="(required)"
                                    className="text-gray-500 font-normal"
                                >
                                    <InputGroup required type="email" value={this.state.email} onChange={this.changeEmail} large id="text-input" placeholder="someone@somewhere.com" />
                                </FormGroup>
                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Password"
                                    labelFor="text-input"
                                    //labelInfo="(required)"
                                    className="text-gray-500 font-normal"
                                >
                                    <InputGroup required  type="password" onChange={this.changePassword}  large id="text-input" placeholder="*****" />
                                </FormGroup>

                                <div className=" flex justify-between items-center">

                                    <div className="mt-2">
                                        <button 
                                            disabled={this.state.loading} 
                                            type="submit" large 
                                            className="px-4 py-2 border border-gray-100 shadow-sm  rounded-md bg-red-750 hover:bg-red-500 duration-50 text-white text-sm font-medium flex items-center" 
                                            intent={Intent.PRIMARY}>{ this.state.loading ? <Spinner size="12" /> : 'Login' }</button>
                                    </div>

                                    <div className="">
                                        <a className="hover:underline" href="/forgotpassword">Forgot Password?</a>
                                    </div>
                                </div>


                            </form>


                            <div className="px-6 py-6 mt-12 bg-gray-50 border-t border-gray-200">
                                    <h5 className="mb1">Not using Emailkick yet?</h5>
                                    <a className="text-blue-700 hover:underline" href="/register">Register</a>
                                </div>


                        </div>
                        <div className="side-wrapper">
                            <h1>Making emails simple.</h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
