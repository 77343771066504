import React, {Component} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link, Redirect, useHistory } from "react-router-dom";
//import Dashboard from './Pages/Dashboard';
//import Builder from './Pages/Builder';
import Login from './Pages/Login';
import { ifToken, DATA_URL, removeToken } from './constants';
import Onboard from './Pages/Onboarding';
import ForgetPassword from './Pages/ForgetPassword';
import ResetPassword from './Pages/ResetPassword';
import ResetPasswordInvalid from './Pages/ResetPasswordInvalid';
import { UserProvider } from './Context/userContext';
import RegisterUser from './Pages/RegisterUser';
import PageLoader from './Components/PageLoader';

import loadable from '@loadable/component'
const DashboardComponent = loadable(() => import('./Pages/Dashboard'), {
  fallback: <PageLoader />,
})


const RedirectApp = (props) => {
  props.history.push("/app")
  return (<div>Redirecting</div>)
}


const Logout = (props) => {
  removeToken();
  window.location.href = '/login'
  return (<div>Logging you out</div>)

}


class App extends Component {

  state = {
    html : ''
  }

  componentDidMount() {
   
  }

  render() {
    return (
      <UserProvider>
        <Router>
            <div className="App">
              <Route path="/" exact component={RedirectApp} />
              <Route path="/app" component={DashboardComponent} />
              <Route path="/login" exact component={Login} />
              <Route path="/forgotpassword" exact component={ForgetPassword} />
              <Route path="/resetpassword/:id" exact component={ResetPassword} />
              <Route path="/resetpasswordInvalid" exact component={ResetPasswordInvalid} />
              <Route path="/register" exact component={RegisterUser} />
              {/* <Route path="/test" exact component={BuilderV3} /> */}
              <Route path="/beta/signup" exact component={Onboard} />
              <Route path="/logout" exact component={Logout} />
            </div>
        </Router>
      </UserProvider>
    );
  }
}

export default App;
