import React, { Component } from 'react'
import { FormGroup, InputGroup, Button, Intent, Spinner } from '@blueprintjs/core';
// import './style.css';
import { INTENT_PRIMARY } from '@blueprintjs/core/lib/esm/common/classes';
import { DATA_URL, setUserInfo, strengthIndicator, strengthColor, LOGOIMGURL } from '../../constants';
export default class Login extends Component {


    state = {
        email: '',
        password: '',
        confirmPassword: '',
        betaCode: '',
        passwordStrength:0,
        passwordStrengthColor:'',
        fullname:'',
        loading:false
    }


    submitHandler = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        })
        fetch(DATA_URL+'auth/beta/register', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
              },
                  body: JSON.stringify({
                      ...this.state
                  }
              ) 
        }).then(r => r.json()).then(r => {
            if(r.user) {
                setUserInfo(r.user);
                window.location.href = '/app'
            }

            else {
                alert(r.message);
                this.setState({
                    loading: false
                })
            }
               
            

        })
    }

    changePassword = (e) => {
        const strngt =  strengthIndicator(e.target.value);
        this.setState({
            password: e.target.value,
            passwordStrength: strngt,
            passwordStrengthColor : strengthColor(strngt)
        });


    }

    changeConfirmPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value,
            confirmPasswordStatus:  e.target.value == this.state.password & e.target.value.length > 3
        })
    }


    changeEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    changeName = (e) => {
        this.setState({
            fullname: e.target.value
        })
    }

    changeBetaCode = (e) => {
        this.setState({
            betaCode: e.target.value
        })
    }
    render() {


        const disabled = this.state.password == this.state.confirmPassword & this.state.password.length > 0 ? false : true;
        return (
            <div className="app-container content-container">
                <div className="container mx-auto pt4  max-width-2">
                    <div className='login-container mt-4' style={{ gridTemplateColumns: '1fr',  }}>
                        
                        <div className="login-form-wrapper">
                            <div className="head">
                                <div className="logo-group flex items-center">
                                    <div className="logo-icon"><img src={LOGOIMGURL} /></div>
                                    <div className="logo-text">emailkick</div>
                                </div>

                                <div style={{ margin: '1rem 0' }}>
                                    <p>Sign up for free. No credit card required.</p>
                                </div>
                            </div>

                           
                            <form onSubmit={this.submitHandler}>
                                <FormGroup  
                                    // helperText="Helper text with details..."
                                    label="Full Name"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required type="text" onChange={this.changeName} large id="text-input" />
                                </FormGroup>
                                
                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Email Address"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required type="email" onChange={this.changeEmail} large id="text-input" placeholder="someone@somewhere.com" />
                                </FormGroup>
                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Password"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required style={this.state.passwordStrength > 0 ? { border: `1px solid ${this.state.passwordStrengthColor}` } : null }  type="password" onChange={this.changePassword}  large id="text-input" placeholder="*****" />
                                </FormGroup>

                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Confirm Password"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required style={ this.state.confirmPasswordStatus ? { border:'1px solid green' } : null }  type="password" onChange={this.changeConfirmPassword}  large id="text-input" placeholder="*****" />
                                </FormGroup>


                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Beta Code"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required type="text" onChange={this.changeBetaCode} large id="text-input" />
                                </FormGroup>



                                <div className="mt3">
                                    <Button type="submit" disabled={disabled} large className="pri-button" intent={Intent.PRIMARY}>{ this.state.loading ? <Spinner size='20' /> : 'Register' }</Button>
                                </div>


                                <div className="mt3">
                                    <h5 className="mb1">Alreay have a emailkick account?</h5>
                                    <a href="/login">Login in now</a>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}
