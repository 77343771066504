import React, { Component, Fragment } from 'react'
import { Spinner } from '@blueprintjs/core';
import Header from '../Header';
import DashSidebar from '../DashSidebar';
export default class PageLoader extends Component {
    render() {
        return (
            <Fragment>
                <Header loading />
                {/* <Spinner /> */}
                <DashSidebar active={this.props.active} />
            </Fragment>
        )
    }
}
