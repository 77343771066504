import React, { useState, useEffect } from 'react';
import { API_HEADERS, DATA_URL, FREE_PLAN_PRODUCT_ID } from '../constants';

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('apitoken') != null ? 'Token ' + localStorage.getItem('apitoken') : null)
    
    const makeUserLoggedIn = (user) => {
        setUser(user)
    }

    const logOutUser = async () => {
        setUser(null);
        setToken(null);
        await localStorage.removeItem('apitoken');
        //window.location.reload();
    }

    useEffect(() => {

        async function checkUser() {
            const userInfo = await fetch(DATA_URL+'/auth/authcheck', {
                method: 'POST',
                headers: API_HEADERS
            });
    
           
            if(userInfo.status == 404) {
                logOutUser();
                return ;
            }
                
    
            if(userInfo.status == 401) {
                logOutUser();
                return ;
            }

            if(userInfo.status == 200) {
                const userInfoSet = await userInfo.json();
                setUser(userInfoSet.user)
                setSubscription(userInfoSet.subscription);
            }

            else {
                logOutUser();
                return ;
            }
    
            
        }

        checkUser();
        return () => {
            
        }
    }, [])


    return (
        <UserContext.Provider value={{ 
            user, 
            makeUserLoggedIn, 
            logOutUser, 
            isFreeUser: subscription ? subscription.priceId.product == FREE_PLAN_PRODUCT_ID ? true : false : true,
            subscription }}>
                
            {children}
        </UserContext.Provider>
    )
}

export { UserProvider, UserContext };