import { Position, Toaster } from "@blueprintjs/core";
import ReactGA from 'react-ga4';
export const AppToaster = Toaster.create({
    className: "recipe-toaster",
    position: Position.BOTTOM,
});



export const API_URL = process.env.REACT_APP_APIURL;
export const ASSETS_API_URL = process.env.REACT_APP_ASSET_API;
export const DATA_URL = API_URL+'/dashapi/';
export const STRIPEKEY = process.env.REACT_APP_STRIPEKEY;
export const FREE_PLAN_PRODUCT_ID = 'prod_JVneTCqxrGeqEX';
export const PRO_PLAN_PRODUCT_ID = 'prod_JVkJzZymhKlbB0';
export const GROW_PLAN_PRODUCT_ID = 'prod_JX3DRhap3rPbmH';
export const ProductIdMeta = {
    'prod_JVneTCqxrGeqEX': {
        id:0,
        name: 'Free',
        price: 0,
        email: 100,
        template: 1,
        id:0,
        desc: 'Play around with Emailkick and get started',
        price: '0',
        priceYearly: '0',
        additionalInfo: 'No credit card required. One Template. 100 emails a month.',
        templateNumber: 'One',
        emailNumbers: '100',
        priceId:'prod_JVneTCqxrGeqEX',
        productId: 'prod_JVneTCqxrGeqEX'
    },
    'prod_JVkJzZymhKlbB0': {
        id:1,
        name: 'Pro',
        price: 0,
        email: 10000,
        template: 5,
        desc: 'Production ready and launch ',
        price: '8',
        priceYearly: '80',
        priceId: 'price_1Isip3EoKz9RzRBEvv5o9IcG',
        yearlyPriceId: 'price_1IsiqTEoKz9RzRBEYai2cZFc',
        additionalInfo: '5 Templates and 20K emails a month',
        templateNumber: '5',
        emailNumbers: '20,000',
        productId: 'prod_JVkJzZymhKlbB0'
    },
    'prod_JX3DRhap3rPbmH': {
        id:2,
        name: 'Scale',
        price: 0,
        email: 50000,
        template: 'Unlimted',
        desc: 'Great for growing companies. ',
        price: '24',
        priceYearly: '240',
        additionalInfo: 'Unlimited Templates and 50K emails a month',
        priceId: 'price_1Itz7PEoKz9RzRBEc7jcGLEI',
        yearlyPriceId: 'price_1Itz7PEoKz9RzRBE7Nd6tUHy',
        templateNumber: 'Unlimited',
        emailNumbers: '50,000',
        productId: 'prod_JX3DRhap3rPbmH'
    }
}


export const getAllPlans = async () => {
    const plans = [];

    const plandata = await fetch(DATA_URL+'/sub/plans', {
        headers: API_HEADERS
    });
    if(!plandata.ok)
        return []

    let plandataJson = await plandata.json();

    Object.keys(plandataJson).forEach((key, index) => {
       plans.push(plandataJson[key])
    });

    return plans;
}

export const LOGOIMGURL = "/images/mailsendLogo.svg";

//export const DATA_URL = 'http://35.238.154.132/dashapi/'
export const API_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'authorization': localStorage.getItem('apitoken') != null ? 'Token ' + localStorage.getItem('apitoken') : 'Null'
}

export const API_HEADERS_AUTH = {
    'authorization': localStorage.getItem('apitoken') != null ? 'Token ' + localStorage.getItem('apitoken') : 'Null'
}


export const SEND_API_URL = process.env.REACT_APP_SEND_API_URL;

export const SEND_API_FORMAT = (id) => {
    return ({
        "APIKEY":"YOUR_API_KEY",
        "ACCESSID":"YOUR_ACCESS_KEY",
        "to": "bar@example.com",
        "from": "you@YOUR_DOMAIN_NAME",
        "templateId": id,
        "variables": {
            "variable_name-1": "Hello",
            "variable_name-2": "Cool Text Here"
        },
      
    })
}

export const findVariablesFromHTML = (body) => {
    let m;
    const regex = /{{([^}]+)}}+/g;
    const spaceRex = /([A-Z,a-z])\w+/g;
    // while ((m = regex.exec(body)) !== null) {
    //     // This is necessary to avoid infinite loops with zero-width matches
    //     if (m.index === regex.lastIndex) {
    //         regex.lastIndex++;
    //     }
    //     console.log(m)
    // }

    let newList = []

    if(body) {
        m = body.match(regex);
        newList = m > 0 ?  m.map( value => value.match(spaceRex)[0]) : [];
    }
    

    return newList;
}


export const setUserInfo = (user) => {
    ReactGA.set({ userId: user._id });
    localStorage.setItem('userInfo', JSON.stringify(user));
    localStorage.setItem('apitoken', user.token)
}

export const ifToken = () => {
    return localStorage.getItem('apitoken')
}

export const removeToken = (user) => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('apitoken')
}

export const servicesList = [{
    id:'sendgrid',
    name: 'SendGrid',
    domain: 'https://sendgrid.com',
    logo: 'https://sendgrid.com/brand/sg-twilio/sg-twilio-lockup.svg'
}, {
    id:'mailgun',
    name: 'MaiilGun',
    domain: 'https://mailgun.com',
    logo: 'https://partnerpage.io/media/contact_images/5e7d1ea4-472b-4d06-9101-4e0c64e57eb6/mailgun_logo.png'
}, {
    id:'ses',
    name:'Amazon SES',
    domain: 'http://aws.com',
    logo:'https://logos-download.com/wp-content/uploads/2016/12/Amazon_Web_Services_logo_AWS-700x305.png'
}]

export const regionOptions = [{
    id:0,
    value: 'us-east-1',
    label:'us-east-1',
},{
    id:1,
    value: 'us-west-2',
    label:'us-west-2',
},{
    id:2,
    value: 'ap-south-1',
    label:'ap-south-1',
},{
    id:3,
    value: 'ap-southeast-2',
    label:'ap-southeast-2',
},{
    id:4,
    value: 'eu-central-1',
    label:'eu-central-1',
},{
    id:5,
    value: 'eu-west-1',
    label:'eu-west-1',
}]




const hasNumber = value => {
    return new RegExp(/[0-9]/).test(value);
 }
 const hasMixed = value => {
    return new RegExp(/[a-z]/).test(value) &&
             new RegExp(/[A-Z]/).test(value);
 }
 const hasSpecial = value => {
    return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
 }
 export const strengthColor = count => {
    if (count < 2)
       return 'red';
    if (count < 3)
       return 'orange';
    if (count < 4)
       return 'lightgreen';
    if (count < 5)
       return 'green';
    if (count < 6)
       return 'green';
 }
 export const strengthIndicator = value => {
    let strengths = 0;
    if (value.length > 5)
       strengths++;
    if (value.length > 7)
       strengths++;
    if (hasNumber(value))
       strengths++;
    if (hasSpecial(value))
       strengths++;
    if (hasMixed(value))
       strengths++;
    return strengths;
 }