import React, { Component, Fragment, useContext, useEffect } from 'react'
import { Spinner, Icon } from '@blueprintjs/core';
import { UserContext } from '../../Context/userContext';
import { Link } from 'react-router-dom';
import { ProductIdMeta } from '../../constants';
const subscriptionPriceName = {
    'price_123': {
        name: 'Pro',
        color: 'blue'
    },
    'price_323': {
        name: 'Pro',
        color: 'blue'
    }
}

const PlanType = (props) => {
    const authUser = useContext(UserContext);
   
    if(authUser.subscription && authUser.subscription.priceId)
        return (
        <div className="border-gray-200 border text-gray-700 text-sm px-3 py-1 rounded-full">
            <span>{authUser.subscription.meta ? authUser.subscription.meta.name : 'Free' }</span>
            {authUser.subscription.meta.id == 'FREE' ? <Link to={'/app/settings/plans'} className="text-blue-500 ml-2">Upgrade</Link> : null }
        </div>)

    return (<div className="border-gray-200 border text-gray-500 text-sm px-3 py-1 rounded-full">Loading Plan</div>)
}


const Header = (props) => {

    useEffect(() => {
        var HW_config = {
            selector: ".headwayapp-icon", // CSS selector where to inject the badge
            account:  "7egOpy",
            trigger:'.headwayapp-icon'
          };


        if(window.Headway)
            window.Headway.init(HW_config);
        return () => {
            
        }
    }, [])



    
    return (
        <header className="App-header">
                <div className="app-header-left">
                
                    <span className="logoIcon flex items-center">
                        <a className="flex" href="/app">
                            <img style={{ width:'30px' }} src='/images/mailsendLogo.svg' />
                        </a>
                        <span className="text-blue-600 bg-blue-100 rounded-3xl px-2 py-1 font-medium text-xs ml-2">Beta</span>
                        <span className="headwayapp-icon"></span>
                        { props.backText ? <a className='ml2 flex items-center text-sm' href={props.backHref}><Icon icon="arrow-left" className="mr1" /> Back to Dashboard</a> : null }
                    </span>
                
                    {/* <span className="logoWrap">mailsend</span> */}
                
                </div>
                {props.loading ? 
                    <Fragment><Spinner size={25} />{!props.noLogin ?
                        <PlanType /> : null }</Fragment> : 
                    <Fragment>
                        {props.children}
                        {!props.noLogin ?
                        <PlanType /> : null }
                    </Fragment>
                }
                   
                {/* { showLogin } */}
              
        </header>
    )
}
export default Header

class Header1 extends Component {
    render() {
        return (
            <header className="App-header">
                <div className="app-header-left">
                
                    <span className="logoIcon flex items-center">
                        <a className="flex" href="/app">
                            <img style={{ width:'30px' }} src='/images/mailsendLogo.svg' />
                        </a>
                        { this.props.backText ? <a className='ml2 flex items-center text-sm' href={this.props.backHref}><Icon icon="arrow-left" className="mr1" /> Back to Dashboard</a> : null }
                    </span>
                
                    {/* <span className="logoWrap">mailsend</span> */}
                
                </div>
                    {this.props.loading ? <Fragment><Spinner size={25} /><div></div></Fragment> : <Fragment>{this.props.children}</Fragment>}
                    {/* {this.props.children} 
                 */}
                
                <div className="">

                </div>
              
            </header>
        )
    }
}
