import React, { Component } from 'react'
import { FormGroup, InputGroup, Button, Intent, Spinner } from '@blueprintjs/core';
// import './style.css';
import { INTENT_PRIMARY } from '@blueprintjs/core/lib/esm/common/classes';
import { DATA_URL, setUserInfo, strengthIndicator, strengthColor, LOGOIMGURL } from '../../constants';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

export default class RegisterUser extends Component {


    state = {
        email: '',
        password: '',
        confirmPassword: '',
        betaCode: 'beta',
        passwordStrength:0,
        passwordStrengthColor:'',
        fullname:'',
        loading:false,
        gtoken: ''
    }


    handleGoogleVerify = (e) => {

        this.setState({
            gtoken: e
        })
    }


    submitHandler = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        })
        fetch(DATA_URL+'auth/registeru', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
              },
                  body: JSON.stringify({
                      ...this.state
                  }
              ) 
        }).then(r => r.json()).then(r => {
            if(r.user) {
                setUserInfo(r.user);
                window.location.href = '/app'
            }

            else {
                alert(r.message);
                this.setState({
                    loading: false
                })
            }
               
            

        })
    }

    changePassword = (e) => {
        const strngt =  strengthIndicator(e.target.value);
        this.setState({
            password: e.target.value,
            passwordStrength: strngt,
            passwordStrengthColor : strengthColor(strngt)
        });


    }

    changeConfirmPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value,
            confirmPasswordStatus:  e.target.value == this.state.password & e.target.value.length > 3
        })
    }


    changeEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    changeName = (e) => {
        this.setState({
            fullname: e.target.value
        })
    }

    changeBetaCode = (e) => {
        this.setState({
            betaCode: e.target.value
        })
    }
    render() {


        const disabled = this.state.password == this.state.confirmPassword & this.state.password.length > 0 ? false : true;
        return (
            <GoogleReCaptchaProvider reCaptchaKey="6Lc0ReUaAAAAAMgjAGyMkb62XKUfzcljznNySQyt">
            <div className="app-container content-container">
                <div className="container mx-auto pt4 max-w-md">
                    <div className='login-container rounded-lg border-gray-100 shadow-sm mt-4' style={{ gridTemplateColumns: '1fr',  }}>
                        
                        <div className="">
                            <div className="head px-4 border-b border-gray-200 py-6">
                                <div className="logo-group flex items-center">
                                    <div className="logo-icon"><img src={LOGOIMGURL} /></div>
                                    <div className="logo-text">emailkick</div>
                                </div>

                                <div className="mt-2">
                                    <p className="text-gray-500">Sign up for free. No credit card required.</p>
                                </div>
                            </div>

                           
                            <form className="mt-6 px-4" onSubmit={this.submitHandler}>
                                <FormGroup  
                                    // helperText="Helper text with details..."
                                    label="Full Name"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required type="text" onChange={this.changeName} large id="text-input" />
                                </FormGroup>
                                
                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Email Address"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required type="email" onChange={this.changeEmail} large id="text-input" placeholder="someone@somewhere.com" />
                                </FormGroup>
                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Password"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required style={this.state.passwordStrength > 0 ? { border: `1px solid ${this.state.passwordStrengthColor}` } : null }  type="password" onChange={this.changePassword}  large id="text-input" placeholder="*****" />
                                </FormGroup>

                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Confirm Password"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required style={ this.state.confirmPasswordStatus ? { border:'1px solid green' } : null }  type="password" onChange={this.changeConfirmPassword}  large id="text-input" placeholder="*****" />
                                </FormGroup>

{/* 
                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Beta Code"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required type="text" onChange={this.changeBetaCode} large id="text-input" />
                                </FormGroup> */}

                                <GoogleReCaptcha onVerify={this.handleGoogleVerify} />

                                <div className="mt3">
                                    <button
                                        type="submit"
                                        disabled={this.state.loading}
                                        className="px-4 py-2 rounded-md w-full text-center bg-blue-600 hover:bg-blue-800 flex justify-center items-center">
                                            { this.state.loading ? <span className="mr-2"><Spinner size={18} intent={Intent.SUCCESS} /></span> : null }
                                            <span className="text-base text-white">Register</span>
                                    </button>

                                    {/* <Button 
                                        disabled={this.state.loading} 
                                        type="submit" 
                                        disabled={disabled} 
                                        large 
                                        className="pri-button" intent={Intent.PRIMARY}>{ this.state.loading ? <Spinner size='20' /> : 'Register' }</Button> */}
                                </div>


                              
                            </form>

                            <div className="mt3 px-4 border-t border-gray-100 py-6">
                                    <h5 className="mb1">Already have an emailkick account?</h5>
                                    <a className="text-blue-700" href="/login">Login in now</a>
                                </div>


                        </div>
                        
                    </div>
                </div>
            </div>
            </GoogleReCaptchaProvider>
        )
    }
}
