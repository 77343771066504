import React, { Component } from 'react'
import { FormGroup, InputGroup, Button, Intent, Spinner, Callout } from '@blueprintjs/core';
// import './style.css';
import { DATA_URL, setUserInfo, strengthIndicator, strengthColor, LOGOIMGURL } from '../../constants';




const SuccessMessage = () => {
    return (
        <div className="app-container content-container">
            <div className="container mx-auto pt4  max-width-2">
                <div className='login-container mt-4' style={{ gridTemplateColumns: '1fr',  }}>
                    <div className="login-form-wrapper">
                        <div className="head">
                     
                                <div className="logo-group">
                                    <div className="logo-icon"><img src={LOGOIMGURL} /></div>
                                    <div className="logo-text">emailkick</div>
                                </div>

                                <div style={{ margin: '3rem 0 0' }}>
                                    <Callout intent={Intent.SUCCESS} icon="tick-circle" title="Password Reset Done">
                                        <p className="py2">Password has been successfully changed.</p>
                                        <a className="bp3-button bp3-large" href="/login">Login to Dashboard</a>
                                    </Callout>
                                </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
    
}
export default class ResetPassword extends Component {


    state = {
        email: '',
        loading:false,
        success: false,
        error: null,
        password: '',
        confirmPassword: '',
    }


    submitHandler = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        })
        fetch(DATA_URL+'auth/forgetpasswordconfirm', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                
              },
                  body: JSON.stringify({
                      token: this.props.match.params.id,
                      ...this.state
                  }
              ) 
        }).then(r => r.json()).then(r => {
            if(r.success) {
                this.setState({
                    success: true
                })
            }

            else {
                this.setState({
                    loading: false,
                    error: r.error
                })
            }
        }).catch(err => {
            this.setState({
                loading: false,
                error: 'Server Issue'
            })
        })
    }

    changePassword = (e) => {
        const strngt =  strengthIndicator(e.target.value);
        this.setState({
            password: e.target.value,
            passwordStrength: strngt,
            passwordStrengthColor : strengthColor(strngt)
        });


    }

    changeConfirmPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value,
            confirmPasswordStatus:  e.target.value == this.state.password & e.target.value.length > 3
        })
    }


    changeEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    changeName = (e) => {
        this.setState({
            fullname: e.target.value
        })
    }

    changeBetaCode = (e) => {
        this.setState({
            betaCode: e.target.value
        })
    }
    render() {


        if(this.state.success)
            return <SuccessMessage />


        const disabled = this.state.password == this.state.confirmPassword & this.state.password.length > 0 ? false : true;

        return (
            <div className="app-container content-container">
                <div className="container mx-auto pt4  max-width-2">
                    <div className='login-container mt-4' style={{ gridTemplateColumns: '1fr',  }}>
                        
                        <div className="login-form-wrapper">
                            <div className="mb1 head ">
                                <div className="logo-group">
                                    <div className="logo-icon"><img src={require('../../assets/images/mailsendLogo.svg')} /></div>
                                    <div className="logo-text">emailkick</div>
                                </div>


                                <div style={{ margin: '1rem 0 0' }}>
                                    <h3 className="mb1 strong mt3">Reset Your Password</h3>
                                    <p>Almost done. Enter your new password, and you're good to go.</p>
                                </div>
                            </div>

                            { this.state.error ? <Callout className="mb2" intent={Intent.WARNING} title={this.state.error}></Callout> : null }

                            <form onSubmit={this.submitHandler}>
                            <FormGroup
                                    // helperText="Helper text with details..."
                                    label="New Password"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required style={this.state.passwordStrength > 0 ? { border: `1px solid ${this.state.passwordStrengthColor}` } : null }  type="password" onChange={this.changePassword}  large id="text-input" placeholder="*****" />
                                </FormGroup>

                                <FormGroup
                                    // helperText="Helper text with details..."
                                    label="Confirm new Password"
                                    labelFor="text-input"
                                    labelInfo="(required)"
                                >
                                    <InputGroup required style={ this.state.confirmPasswordStatus ? { border:'1px solid green' } : null }  type="password" onChange={this.changeConfirmPassword}  large id="text-input" placeholder="*****" />
                                </FormGroup>
                               
                                <div className="mt3">
                                    <Button type="submit" disabled={disabled} large className="pri-button" intent={Intent.PRIMARY}>{ this.state.loading ? <Spinner size='20' /> : 'Reset Password' }</Button>
                                </div>

                                <div className="mt4">
                                    <h5 className="mb1">Back to Login?</h5>
                                    <a href="/login">Login in now</a>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}
